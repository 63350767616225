import { type ErrorMapCtx, type ZodIssueOptionalMessage, z } from "zod";
import { makeInflect } from "../utils/makeInflect.js";
import { sameValueForEveryLocale } from "../utils/sameValueForEveryLocale.js";
import { toDateTimeMonthYearFormat } from "../utils/toDateTimeMonthYearFormat.js";
import { Locale, type LocalizedString } from "./Locale.js";

const inflectElement = makeInflect("prvek", "prvky", "prvků");
const inflectCharacter = makeInflect("znak", "znaky", "znaků");

function localizedErrorMap(issue: ZodIssueOptionalMessage, _ctx: ErrorMapCtx): { message: LocalizedString } {
	switch (issue.code) {
		case z.ZodIssueCode.invalid_string: {
			switch (issue.validation) {
				case "email": {
					return {
						message: {
							[Locale.cs]: "E-mailová adresa není platná",
						},
					};
				}
				case "url": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platný odkaz",
						},
					};
				}
				case "emoji": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné emoji",
						},
					};
				}
				case "uuid": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné UUID",
						},
					};
				}
				case "nanoid": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné Nano ID",
						},
					};
				}
				case "regex": {
					return {
						message: {
							[Locale.cs]: "Pole nesplňuje požadovaný formát",
						},
					};
				}
				case "cuid": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné CUID",
						},
					};
				}
				case "cuid2": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné CUID2",
						},
					};
				}
				case "ulid": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné ULID",
						},
					};
				}
				case "datetime": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné datum a čas",
						},
					};
				}
				case "date": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné datum",
						},
					};
				}
				case "time": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platný čas",
						},
					};
				}
				case "duration": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platnou dobu",
						},
					};
				}
				case "ip": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platnou IP adresu",
						},
					};
				}
				case "base64": {
					return {
						message: {
							[Locale.cs]: "Nejedná se o platné Base64",
						},
					};
				}
			}

			if ("startsWith" in issue.validation) {
				return {
					message: {
						[Locale.cs]: `Pole musí začínat na ${issue.validation.startsWith}`,
					},
				};
			} else if ("endsWith" in issue.validation) {
				return {
					message: {
						[Locale.cs]: `Pole musí končit na ${issue.validation.endsWith}`,
					},
				};
			}

			return {
				message: {
					[Locale.cs]: `Pole musí obsahovat ${issue.validation.includes}${issue.validation.position !== undefined ? ` od pozice ${issue.validation.position}` : ""}`,
				},
			};
		}
		case z.ZodIssueCode.invalid_date:
			return {
				message: {
					[Locale.cs]: "Pole musí být validní datum",
				},
			};
		case z.ZodIssueCode.invalid_type:
			if (issue.expected !== "undefined" && issue.received === "undefined") {
				return {
					message: {
						[Locale.cs]: "Pole je povinné",
					},
				};
			}

			return {
				message: {
					[Locale.cs]: "Neplatná hodnota",
				},
			};
		case z.ZodIssueCode.unrecognized_keys:
			return {
				message: {
					[Locale.cs]: "Pole nesmí obsahovat neznámé klíče",
				},
			};
		case z.ZodIssueCode.too_small: {
			const min = issue.inclusive ? issue.minimum : BigInt(issue.minimum) + BigInt(1);
			switch (issue.type) {
				case "set":
				case "array": {
					return {
						message: {
							[Locale.cs]: `Pole musí obsahovat alespoň ${min} ${inflectElement(min)}`,
						},
					};
				}
				case "string":
					if (min === 1) {
						return {
							message: {
								[Locale.cs]: "Pole nesmí být prázdné",
							},
						};
					}

					return {
						message: {
							[Locale.cs]: `Pole musí obsahovat alespoň ${min} ${inflectCharacter(min)}`,
						},
					};
				case "bigint":
				case "number":
					return {
						message: {
							[Locale.cs]: `Pole musí být větší nebo rovno ${min}`,
						},
					};
				case "date":
					return {
						message: {
							[Locale.cs]: `Datum musí být po ${toDateTimeMonthYearFormat(new Date(Number(min)), Locale.cs)}`,
						},
					};
			}
			break;
		}
		case z.ZodIssueCode.too_big: {
			const max = issue.inclusive ? issue.maximum : BigInt(issue.maximum) + BigInt(1);
			switch (issue.type) {
				case "set":
				case "array": {
					return {
						message: {
							[Locale.cs]: `Pole nesmí obsahovat více než ${max} ${inflectElement(max)}`,
						},
					};
				}
				case "string":
					return {
						message: {
							[Locale.cs]: `Pole nesmí obsahovat více než ${max} ${inflectCharacter(max)}`,
						},
					};
				case "bigint":
				case "number":
					return {
						message: { [Locale.cs]: `Pole musí být menší nebo rovno ${max}` },
					};
				case "date":
					return {
						message: {
							[Locale.cs]: `Datum musí být před ${toDateTimeMonthYearFormat(new Date(Number(max)), Locale.cs)}`,
						},
					};
			}
			break;
		}
		case z.ZodIssueCode.invalid_literal:
		case z.ZodIssueCode.invalid_union:
		case z.ZodIssueCode.invalid_union_discriminator:
		case z.ZodIssueCode.invalid_enum_value:
		case z.ZodIssueCode.invalid_arguments:
		case z.ZodIssueCode.invalid_return_type:
		case z.ZodIssueCode.invalid_intersection_types:
		case z.ZodIssueCode.not_multiple_of:
		case z.ZodIssueCode.not_finite:
		case z.ZodIssueCode.custom:
			return { message: sameValueForEveryLocale(issue.message ?? "") };
	}
}

function zodErrorMap(issue: ZodIssueOptionalMessage, ctx: ErrorMapCtx, locale: Locale): { message: string } {
	return {
		message: localizedErrorMap(issue, ctx).message[locale],
	};
}

// Calling it as a side effect of an import, otherwise it doesn't work both locally and in the built apps :trollface:
z.setErrorMap((issue, ctx) => zodErrorMap(issue, ctx, Locale.cs));
