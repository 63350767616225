import { enableCache } from "@iconify/svelte";
import type { HandleClientError } from "@sveltejs/kit";
import "@core/schema/zodErrorMap";

enableCache("session");

export const handleError = (input: Parameters<HandleClientError>[0]): ReturnType<HandleClientError> => {
	if (input.error instanceof Error) {
		return {
			message: input.error.message,
		};
	}
};
