import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/(admin)": [5,[2]],
		"/(static)/help": [13,[4]],
		"/(auth)/login": [11,[3]],
		"/(admin)/permissions": [6,[2]],
		"/(admin)/permissions/add": [8,[2]],
		"/(admin)/permissions/[user]": [7,[2]],
		"/(admin)/requests": [9,[2]],
		"/(admin)/requests/[id]": [10,[2]],
		"/(auth)/session": [12,[3]],
		"/(static)/styles": [14,[4]],
		"/[...path]": [15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';